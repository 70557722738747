



















import { AuthActions } from '@/store/modules/auth/enums';
import { Component, Vue } from 'vue-property-decorator';
import { DashboardActions } from '@/store/enums';
import { MediasActions, MediasGetters } from '@/store/modules/medias/enums';
import {
  MerchantConfigActions,
  MerchantConfigGetters,
} from '@/store/modules/merchant-config/enums';
import { MerchantMigrationActions } from '@/store/modules/merchant-migration/enums';
import { NexiPayload } from '@/models/web-payments/nexiPayload';
import { QRActions } from '@/models/common/constants';
import {
  WebPaymentsActions,
  WebPaymentsGetters,
} from '@/store/modules/web-payments/enums';
import { WebPaymentServiceType } from '@/models/web-payments/webPaymentServiceType';
import CustomerDashboard from '@/views/customer/CustomerDashboard.vue';
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import HeaderBackground from '@/components/layout/HeaderBackground.vue';
import { addBackToTop } from 'vanilla-back-to-top';

@Component({
  components: {
    'ck-customer-dashboard': CustomerDashboard,
    'ck-header': Header,
    'ck-header-background': HeaderBackground,
    'ck-footer': Footer,
  },
})
export default class Customer extends Vue {
  public loaded = false;
  public showAccount = false;
  public mediaSerial!: string;
  public deviceId!: string;
  public action!: DashboardActions;
  public checkoutSuccessModal = false;
  public checkoutMsg!: string;

  created(): void {
    this.showAccount = this.$route.params.showAccount as unknown as boolean;
    this.mediaSerial = this.$route.params.serial as string;
    this.deviceId = this.$route.params.deviceId as string;
    this.action = this.getAction();
    if (this.selectedServiceType == WebPaymentServiceType.Nexi) {
      this.processNexiCheckout();
    }
    this.getWebPaymentServices();
  }

  get selectedServiceType(): WebPaymentServiceType {
    return this.$store.getters[WebPaymentsGetters.GetSelectedServiceType];
  }

  closeCheckoutModal(): void {
    this.checkoutSuccessModal = false;
  }

  isBack(): void {
    this.showAccount = false;
  }

  processNexiCheckout(): void {
    this.checkoutSuccessModal = this.$route.params
      .checkout as unknown as boolean;
    this.checkoutMsg = this.$route.params.checkoutMsg as string;
    if (!!this.checkoutMsg && this.checkoutMsg != 'Message OK') {
      this.checkoutSuccessModal = false;
      this.$toaster.showError(
        this.$t('webpayment.checkout-error') + ': ' + this.checkoutMsg,
      );
      this.$store.dispatch(
        WebPaymentsActions.SetNexiPayload,
        new NexiPayload(),
      );
      this.$store.dispatch(WebPaymentsActions.ResetNexiTries);
      this.action = DashboardActions.OnlineRecharge;
    } else if (this.checkoutMsg == 'Message OK') {
      this.$store.dispatch(
        WebPaymentsActions.SetNexiPayload,
        new NexiPayload(),
      );
      this.$store.dispatch(WebPaymentsActions.ResetNexiTries);
      this.action = this.getAction();
    }
  }

  getAction(): DashboardActions {
    if (this.$route.params.action == QRActions.Recharge) {
      return DashboardActions.OnlineRecharge;
    }
    return DashboardActions.DashboardView;
  }

  refreshMedias(): void {
    this.retrieveMedias(false);
  }

  async mounted(): Promise<void> {
    if (!document.querySelectorAll('body > #back-to-top').length)
      addBackToTop();
    this.loaded = this.$route.params.loaded as unknown as boolean;
    if (!this.loaded) {
      this.retrieveMedias(true);
    }
  }

  retrieveMedias(init: boolean): void {
    this.$spinner.showSpinner();
    this.$store
      .dispatch(MediasActions.GetMedias, init)
      .then((medias) => {
        if (medias.length > 0) {
          this.$store
            .dispatch(
              MerchantConfigActions.GetMerchantConfig,
              this.$store.getters[MediasGetters.GetMerchantId],
            )
            .then(() => {
              if (!this.landingPageEnabled()) {
                this.clearConfigAndMedias();
              } else {
                this.$store.dispatch(
                  WebPaymentsActions.GetConfigs,
                  this.$store.getters[MediasGetters.GetMerchantId],
                );
                this.$store.dispatch(
                  MerchantMigrationActions.GetMigratedMerchants,
                  this.$store.getters[MediasGetters.GetMerchantId],
                );
              }
              this.loaded = true;
            });
        } else {
          this.loaded = true;
        }
      })
      .catch(() => {
        this.loaded = true;
      })
      .finally(() => {
        this.$spinner.removeSpinner();
      });
  }

  protected landingPageEnabled(): boolean {
    return (
      this.$store.getters[MerchantConfigGetters.GetMerchantConfig]?.services
        ?.length > 0
    );
  }

  getWebPaymentServices(): void {
    this.$store.dispatch(WebPaymentsActions.GetServices);
  }

  account(): void {
    this.showAccount = true;
  }

  async logout(): Promise<void> {
    this.$spinner.showSpinner();
    await this.$msal.signOut().then(() => {
      this.loaded = false;
      this.$store.dispatch(AuthActions.Logout);
      this.clearConfigAndMedias();
    });
  }

  async clearConfigAndMedias(): Promise<void> {
    this.$store.dispatch(MerchantConfigActions.Clear);
    this.$store.dispatch(MediasActions.Clear);
    this.$store.dispatch(WebPaymentsActions.Clear);
    this.$store.dispatch(MerchantMigrationActions.Clear);
  }
}
